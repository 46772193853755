<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>
            {{ this.$t('report.company_dashboard.reports') }}
          </li>
          <li>{{ this.$t('general.user_content_report') }}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left"><span>{{ this.$t('general.user_content_report') }} </span></h2>
      </div>
    </div>
    <!--Modal Profie-->

    <div v-if="error" class="uk-alert-danger mb-3" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color:red;">
        <i class="uil-exclamation-triangle"></i> {{ error }}
      </p>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                     class="uk-search-input shadow-0 uk-form-small" type="search"/>
              <!-- <a href="#" class="btn btn-icon btn-hover btn-circle" aria-expanded="false">
              <i class="uil-search"></i>
            </a>
            <div class="uk-drop" uk-drop="mode: hover; pos: right-center; offset: 0" style="left: 91px; top: 159.297px;">
              <form class="uk-search uk-search-navbar uk-width-1-1">
                <input class="uk-search-input shadow-0 uk-form-small"
                type="search"
                  v-model="filter.search"
                  :placeholder="$t('general.search')" autofocus="">
              </form>-->
            </div>
          </div>
          <!-- <div>
            <a aria-expanded="false" class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)">
              <i class="uil-search"></i>
            </a>
            <div class="uk-drop" style="left: 91px; top: 159.297px;"
                 uk-drop="mode: hover; pos: right-center; offset: 0">
                <span class="uk-search uk-search-navbar uk-width-1-1">
                  <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                         class="uk-search-input shadow-0 uk-form-small" type="search">
                </span>
            </div>
          </div> -->
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover  btn-circle"
               href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{
                    $t('sorting.a_z')
                  }}</a></li>
                <li><a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t('sorting.z_a') }}</a></li>
                <li><a href="javascript:void(0)" @click="sortOrder('asc', 'id')"> {{ $t('sorting.created_at_old') }}</a>
                </li>
                <li><a href="javascript:void(0)" @click="sortOrder('desc', 'id')"> {{
                    $t('sorting.created_at_new')
                  }}</a>
                </li>
              </ul>
            </div>

            <!-- Filter -->
            <a :uk-tooltip="$t('general.filter_list')" class="btn btn-icon btn-hover  btn-circle"
               href="javascript:void(0)">
              <i class="uil-filter"></i>
            </a>
            <div id="filterDropdown" class="large"
                 uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <div class="filter-container">
                <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>
                  <app-multiselect v-model="content_ids" :is-multiple="true" :options="userList"
                                   :placeholder="$t('exam.choose_content')" :title="$t('messages.content')"
                                   @input="getAssignments"/>

                  <app-multiselect v-model="assignment_ids" :is-multiple="true" :options="assignmentList"
                                   :placeholder="$t('exam.choose_education')" :title="$t('education.education')"
                                   @input="getAssignments"/>

                  <div>
                    <h5 class="uk-text-bold mb-2">{{ $t('general.date') }}</h5>
                    <VueCtkDateTimePicker v-model="selectedDate" :format="'YYYY-MM-DD HH:mm:ss'" :no-button-now="true"
                                          :no-shortcuts="true" :range="true"/>
                  </div>
                </div>

                <div class="uk-flex uk-flex-right p-2">
                  <button class="btn btn-default grey" @click="filterResult"><i class="uil-filter"></i>
                    {{ $t('general.filter') }}
                  </button>
                </div>
              </div>
            </div>
            <a :uk-tooltip="$t('filters.export_excell')" class="btn btn-icon btn-hover  btn-circle" href="javascript:;"
               @click="exportUserContents">
              <i class="fa-file-excel fa"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"></default-loading>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col"> {{ $t('general.training_name') }} </th>
              <th scope="col"> {{ $t('general.content_title') }} </th>
              <th scope="col"> {{ $t('general.user_first_name') }} </th>
              <th scope="col"> {{ $t('general.user_last_name') }} </th>
              <th scope="col"> {{ $t('general.user_status') }} </th>
              <th scope="col"> {{ $t('excel.heading.email') }} </th>
              <th scope="col"> {{ $t('general.register_number') }} </th>
              <th scope="col"> {{ $t('excel.heading.started_at') }} </th>
              <th scope="col"> {{ $t('excel.heading.completed_at') }} </th>
              <!-- <th scope="col">Adımlar</th> -->
              <th scope="col"> {{ $t('general.completion_status') }} </th>
              <th scope="col"> {{ $t('excel.heading.point') }} </th>
            </tr>
            </thead>
            <tbody class="list">
            <template v-for="item in formattedItems">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.assignment_name }}</td>
                <td>{{ item.content_name }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.surname }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.registration_number }}</td>
                <td> {{ item.start_date }}</td>
                <td> {{ item.end_date }}</td>
                <!-- <td> {{ item.assignment_completed_count }} / {{ item.assignment_count }}</td> -->
                <td> {{ item.is_completed }}</td>
                <td> {{ item.is_completed === 'Tamamlandı' ? item.point : '-' }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {ERROR, GET_ITEMS, ITEMS, LOADING, MODULE_NAME, SUCCESS} from "@/core/services/store/reports.module";

import store from '@/core/services'
import moment from "moment-timezone";
import axios from "axios";
import UserProfile from "@/view/company/User/UserProfile";
import UserFilter from "@/view/company/User/Filter";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Multiselect from "vue-multiselect";
import Pagination from "@/assets/components/Pagination";
import AppMultiselect from "@/view/components/select/app-multiselect/app-multiselect.vue";
import {getSubDomainId} from "@/core/services/store.js";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportUsers",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, reports)
  },
  components: {UserProfile, UserFilter, DefaultLoading, Multiselect, Pagination, AppMultiselect},
  data() {
    return {
      from_date: null,
      to_date: null,
      selectedDate: null,
      pagination: null,
      content_ids: [],
      assignment_ids: [],
      activeItem: null,
      error: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      formattedItems: [],
      userList: [],
      assignmentList: [],
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    }
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + '/' + ITEMS]
      },
      set(value) {
      }
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + '/' + LOADING]
      },
      set(value) {
      }
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS]
    }
  },
  methods: {
    exportUserContents() {
      let contentIds = [];
      if (this.content_ids.length > 0) {
        this.content_ids.every((item) => {
          if (item.id === 'all') {
            contentIds = [];
            this.userList.forEach((user) => {
              if (user.id === 'all')
                return;
              contentIds.push(user.id);
            })
            return false
          } else {
            contentIds.push(item.id);
            return true;
          }
        });
      }
      let assignmentIds = [];
      if (this.assignment_ids.length > 0) {
        this.assignment_ids.every((item) => {
          if (item.id === 'all') {
            assignmentIds = [];
            return false
          } else {
            assignmentIds.push(item.id);
            return true;
          }
        });
      }
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + '/api/report/export/user-contents', {
        responseType: 'blob',
        params: {
          content_ids: JSON.stringify(contentIds),
          assignment_ids: JSON.stringify(assignmentIds),
          sort: this.filter.sort,
          order: this.filter.order,
          like: this.filter.search,
          from_date: this.from_date,
          to_date: this.to_date,
          report: Math.random() * 9999999
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.secureLocalStorage("token"),
          Company: getSubDomainId?.value,
        }
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let day = moment().format('YYYYMMDDHHMMSS');
        let fileName = 'user_contents_report_list_' + day + '.xlsx';
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },
    filterResult(filter = null) {
      let self = this;
      this.error = null;
      if (!self.content_ids.length === 0 || self.assignment_ids.length === 0) {
        this.error = this.$t('general.choose_education_info');
        return false;
      }
      //UIkit.dropdown('#filterDropdown').hide();
      let contentIds = [];
      if (this.content_ids.length > 0) {
        this.content_ids.every((item) => {
          if (item.id === 'all') {
            contentIds = [];
            this.userList.forEach((user) => {
              if (user.id === 'all')
                return;
              contentIds.push(user.id);
            })
            return false
          } else {
            contentIds.push(item.id);
            return true;
          }
        });
      }
      let assignmentIds = [];
      if (this.assignment_ids.length > 0) {
        this.assignment_ids.every((item) => {
          if (item.id === 'all') {
            assignmentIds = [];
            return false
          } else {
            assignmentIds.push(item.id);
            return true;
          }
        });
      }


      let filters = {
        content_ids: JSON.stringify(contentIds),
        // content_id: self.content_id.id,
        assignment_ids: JSON.stringify(assignmentIds),
        sort: this.filter.sort,
        order_by: this.filter.order,
        like: this.filter.search,
        per_page: this.per_page,
        page: this.page,
        from_date: this.selectedDate?.start ? new Date(this.selectedDate.start).toISOString().split('T')[0] : null,
        to_date: this.selectedDate?.end ? new Date(this.selectedDate.end).toISOString().split('T')[0] : null,
      };
      this.from_date = filters['from_date'];
      this.to_date = filters['to_date'];
      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/user-contents',
        filters: filters
      }).then(response => {
        if (response.status) {
          let results = [];
          let items = response.data;
          this.pagination = items;
          items.data.forEach(item => {
            let completeStatus = 'Bekliyor'
            if (item.completed_at) {
              completeStatus = "Tamamlandı"
            } else if (item.started_at && !item.completed_at) {
              completeStatus = "Devam Ediyor"
            } else {
              completeStatus = "Bekliyor"
            }

            // let assignmentContent = {};
            // if (item.assignments) {
            //   assignmentContent = item.assignments[0].contents[0];
            // } else {
            //   assignmentContent = item.assignments[0].contents_programs[0];
            // }

            results.push({
              id: item.id,
              assignment_name: item.assignment_title,
              content_name: item.content_title,
              name: item.name,
              surname: item.surname,
              email: item.email,
              point: item.point,
              status: item.status_id == 0 ? "Pasif" : "Aktif",
              registration_number: item.registration_number ?? '-',
              start_date: item.started_at ? moment(item.started_at).add("3", 'hours').format('DD/MM/YYYY HH:mm') : '-',
              end_date: item.completed_at ? moment(item.completed_at).add("3", 'hours').format('DD/MM/YYYY HH:mm') : '-',
              is_completed: completeStatus,
              // assignment_count: item.assignments?.length,
              // assignment_completed_count: item.assignment_completed_contents?.length,
            });
          })
          this.formattedItems = results;
        }
      });
    },
    getContent() {
      let filters = {
        sort: 'desc',
        order_by: 'id',
        program: true,
        sections: false,
        all: true
      };

      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/get-contents',
        filters: filters
      }).then(response => {
        if (response.status) {
          let results = [{
            id: "all",
            name: "Tümü",
          }];
          // let results = [];
          let items = response.data;
          if (items.length > 0) {
            items.forEach(item => {
              results.push({
                id: item.id,
                name: `${item.title} - ${item.name}`,
              });
            })
          }
          this.userList = results;
        }
      });
    },
    getAssignments(value) {
      console.log("getAssignments")
      let ids = []
      // this.content_ids.forEach((items) => {
      //   ids.push(items.id);
      // });
      if (this.content_ids.length > 0) {
        this.content_ids.every((item) => {
          if (item.id === 'all') {
            ids = [];
            return false
          } else {
            ids.push(item.id);
            return true;
          }
        });
      }
      let filters = {
        page: 0,
        order: 'id',
        sort: 'desc',
        content_ids: JSON.stringify(ids),
      };
      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/assignment',
        filters: filters
      }).then(response => {
        if (response.status) {
          let results = [{
            id: "all",
            name: "Tümü",
            full_name: 'Tümü'
          }];
          let items = response.data;
          items.forEach(item => {
            results.push({
              id: item.id,
              name: item.name,
              full_name: item.full_name
            });
          })
          this.assignmentList = results;
        }
      });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;


      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult()
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    this.filterResult();
    this.getContent();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500)

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult()
      }
    },
    'filter.search': function (val) {
      this.debouncedFilterSearch()
    },
    formattedInstitution(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker1').selectpicker();
        }, 200)
      }
    },
    formattedUserGroup(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker2').selectpicker();
        }, 200)
      }
    },
    formattedPosition(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker3').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedTitle(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker4').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedRegion(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker5').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedDepartment(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker6').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedBranch(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker7').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    activeItem(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    }
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  }

};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
